import { Component, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from "../environments/environment";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    static logoUrl: String;

    constructor(
        public vcRef: ViewContainerRef,
        private titleService: Title) {

        let title = 'Rugged Hub';
        if (environment.buildEnv) {
            title += ` - ${environment.buildEnv}`;
        }
        titleService.setTitle(title);

        let favIcon16: HTMLLinkElement = document.querySelector('#appIcon16');
        let favIcon32: HTMLLinkElement = document.querySelector('#appIcon32');

        if (environment.buildEnv.includes('Dev')) {
            favIcon16.href = favIcon16.href.replace("favicon-16x16.png", "favicon-dev-16x16.png");
            favIcon32.href = favIcon32.href.replace("favicon-32x32.png", "favicon-dev-32x32.png");
            AppComponent.logoUrl = 'logo-dev.png';
        }
        else if (environment.buildEnv.includes('Staging')) {
            favIcon16.href = favIcon16.href.replace("favicon-16x16.png", "favicon-staging-16x16.png");
            favIcon32.href = favIcon32.href.replace("favicon-32x32.png", "favicon-staging-32x32.png");
            AppComponent.logoUrl = 'logo-staging.png';
        }
    }

    title = 'rugged-hub';
}
