// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    appVersion: require('../../package.json').version + ' [Dev Legacy]',
    buildEnv: 'Dev Legacy',
    production: false,
    debug: {
        useSampleData: false
    },
    config: {
        // firebase: {
        //     apiKey: "AIzaSyAybmfDD-TjDHR_DZNEpmmRhl7xu1Vp7BU",
        //     authDomain: "rugged-data-dev-db.firebaseapp.com",
        //     databaseURL: "https://rugged-data-dev-db.firebaseio.com",
        //     projectId: "rugged-data-dev-db",
        //     storageBucket: "rugged-data-dev-db.appspot.com",
        //     messagingSenderId: "195321003966",
        //     appId: "1:195321003966:web:d1cf1b540478aec1ce43ef"
        // }
        firebase: {
            apiKey: "AIzaSyB9AqJ9mTnkw69_ToE4_BoEaKDZ54KFjZ0",
            authDomain: "rugged-data-dev.firebaseapp.com",
            databaseURL: "https://rugged-data-dev.firebaseio.com",
            projectId: "rugged-data-dev",
            storageBucket: "rugged-data-dev.appspot.com",
            // messagingSenderId: "195321003966",
            // appId: "1:195321003966:web:d1cf1b540478aec1ce43ef"
        },

        endpoints: {
            legacy:            { url: 'https://api-dot-rugged-data-dev.appspot.com/_ah/api/rugged/v1', type: 'rest'},
            resources:         { url: 'https://us-central1-rugged-data-dev.cloudfunctions.net/resources/graphql', type: 'graphql', attachTypeAs: 'kind'},
            forms:             { url: 'https://us-central1-rugged-data-dev.cloudfunctions.net/forms/graphql', type: 'graphql'},
            files:             { url: 'https://us-central1-rugged-data-dev.cloudfunctions.net/files/graphql', type: 'graphql'},
            concatenatepdf:    { url: 'https://us-central1-rugged-data-dev.cloudfunctions.net/concatenatepdf/graphql', type: 'graphql'},
            tasks:             { url: 'https://us-central1-rugged-data-dev.cloudfunctions.net/tasks/graphql', type: 'graphql'},
            users:             { url: 'https://us-central1-rugged-data-dev.cloudfunctions.net/users/graphql', type: 'graphql'},
            freeuser:          { url: 'https://us-central1-rugged-data-dev.cloudfunctions.net/freeuser', type: 'rest'},
            publicApi:         { url: 'https://us-central1-rugged-data-dev.cloudfunctions.net/api', type: 'graphql'},
            serve:             { url: 'https://us-central1-rugged-data-dev.cloudfunctions.net/serve', type: 'rest'},
            organisations:     { url: 'https://us-central1-rugged-data-dev.cloudfunctions.net/organisations/graphql', type: 'graphql'},
            reportGenerator:   { url: 'https://us-central1-rugged-data-dev.cloudfunctions.net/reportGenerator/graphql', type: 'graphql'},
            reportDefinitions: { url: 'https://us-central1-rugged-data-dev.cloudfunctions.net/reportDefinitions/graphql', type: 'graphql'},
            swagger:           { url: 'https://us-central1-rugged-data-dev.cloudfunctions.net/api/docs/'},
            processing:        { url: 'https://us-central1-rugged-data-dev.cloudfunctions.net/processing/graphql', type: 'graphql'},
            cleanup:           { url: 'https://us-central1-rugged-data-dev.cloudfunctions.net/cleanup/graphql', type: 'graphql'},
        },
        storage: {
            previews: "https://storage.cloud.google.com/rugged-data-dev-data/",
            reports: "https://storage.cloud.google.com/rugged-data-dev-data/"
        }
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
