import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, isObservable, Observable, of, throwError } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from "../../../environments/environment";
import { AuthService } from "../../core/auth/auth.service";
import { Dataset } from "./datasets/datasets.types";
import { Distlist } from "./distlists/distlists.types";
import { Style } from "./styles/styles.types";
import { Header } from './headers/headers.types';
import { Process } from './processes/processes.types';
import { FormsService } from "../forms/forms.service";
import { FormTemplate } from "../forms/forms.types";

@Injectable({
    providedIn: 'root'
})
export class ResourcesService
{
    private _dataset: BehaviorSubject<Dataset | null> = new BehaviorSubject(null);
    private _datasets: BehaviorSubject<Dataset[] | null> = new BehaviorSubject(null);

    private _header: BehaviorSubject<Header | null> = new BehaviorSubject(null);
    private _headers: BehaviorSubject<Header[] | null> = new BehaviorSubject(null);

    private _style: BehaviorSubject<Style | null> = new BehaviorSubject(null);
    private _styles: BehaviorSubject<Style[] | null> = new BehaviorSubject(null);

    private _distlist: BehaviorSubject<Distlist | null> = new BehaviorSubject(null);
    private _distlists: BehaviorSubject<Distlist[] | null> = new BehaviorSubject(null);

    private _process: BehaviorSubject<Process | null> = new BehaviorSubject(null);
    private _processes: BehaviorSubject<Process[] | null> = new BehaviorSubject(null);

    constructor(
        private _authService: AuthService,
        private _formsService: FormsService,
        private _httpClient: HttpClient)
    {
    }

    get dataset(): Dataset {
        return this._dataset.value;
    }

    get dataset$(): Observable<Dataset>
    {
        return this._dataset.asObservable();
    }

    get datasets$(): BehaviorSubject<Dataset[]>
    {
        return this._datasets;
    }


    get header(): Dataset {
        return this._header.value;
    }

    get header$(): Observable<Dataset>
    {
        return this._header.asObservable();
    }

    get headers$(): BehaviorSubject<Dataset[]>
    {
        return this._headers;
    }


    get style(): Style {
        return this._style.value;
    }

    get style$(): Observable<Style>
    {
        return this._style.asObservable();
    }

    get styles$(): BehaviorSubject<Style[]>
    {
        return this._styles;
    }


    get distlist(): Distlist {
        return this._distlist.value;
    }

    get distlist$(): Observable<Distlist>
    {
        return this._distlist.asObservable();
    }

    get distlists$(): BehaviorSubject<Distlist[]>
    {
        return this._distlists;
    }


    get process(): Process {
        return this._process.value;
    }

    get process$(): Observable<Process>
    {
        return this._process.asObservable();
    }

    get processes$(): BehaviorSubject<Process[]>
    {
        return this._processes;
    }


    clearResourcesCache() {
        this._datasets.next([]);
        this._dataset.next(null);

        this._headers.next([]);
        this._header.next(null);

        this._styles.next([]);
        this._style.next(null);

        this._distlists.next([]);
        this._distlist.next(null);

        this._processes.next([]);
        this._process.next(null);
    }

    getLinkedForms(slug: string, kind: 'dataset' | 'header' | 'style' | 'distribution' | 'process'): Observable<FormTemplate[]>
    {
        const orgIdOverride = this._authService.organisationIdOverride$.value;
        const orgOverrideQuery = orgIdOverride ? `organisation:"${orgIdOverride}" ` : '';

        return this._httpClient.post<{data: {resorcelinks: {id: string, form: string, slug: string }[]}}>(environment.config.endpoints.resources.url, {
            query: `query{resorcelinks(${orgOverrideQuery}slug:"${slug}"kind:"${kind}"){id form slug}}` // todo resorcelinks [sic]
        }).pipe(
            take(1),
            map((resourceLinkData) => {

                const formIds = resourceLinkData.data.resorcelinks.map(resourceLink => resourceLink.form);

                if (!this._formsService.forms$.value) {
                    return this._formsService.getForms().pipe(
                        take(1),
                        map((formsData) => {
                            return formsData.data.list.filter(form => formIds.includes(form.id));
                        }));
                }
                else {
                    return this._formsService.forms$.value.filter(form => formIds.includes(form.id));
                }
            }),
            switchMap((resourceLinkData) => {
                if (isObservable(resourceLinkData)) {
                    return resourceLinkData;
                }
                return of(resourceLinkData);
            })
        );
    }

    // DATASETS

    async getDatasets(): Promise<Dataset[]> {
        const orgIdOverride = this._authService.organisationIdOverride$.value;
        const orgOverrideQuery = orgIdOverride ? `organisation:"${orgIdOverride}" ` : '';

        const asyncResult = await this._httpClient.post<{ data: { list: Dataset[] } }>(environment.config.endpoints.resources.url, {
            query: `query{list(${orgOverrideQuery}kind:"dataset"){id name slug status}}`
        }).toPromise();

        let datasets = asyncResult.data.list;
        // if (datasets) {
        //     datasets = datasets.sort((a, b) => {
        //         return (a.name ?? '').localeCompare(b.name ?? '');
        //     });
        // }

        this._datasets.next(datasets);
        return datasets;
    }

    getDatasetById(id: string): Observable<Dataset>
    {
        const orgIdOverride = this._authService.organisationIdOverride$.value;
        const orgOverrideQuery = orgIdOverride ? `organisation:"${orgIdOverride}" ` : '';

        return this._httpClient.post<{data: {get: Dataset}}>(environment.config.endpoints.resources.url, {
            query: `query{get(id:\"${id}\" ${orgOverrideQuery}kind:"dataset"){id name data slug status}}`
        }).pipe(
            take(1),
            map((datasetData) => {

                // Update the dataset
                this._dataset.next(datasetData.data.get);

                // Return the dataset
                return datasetData.data.get;
            }),
            switchMap((dataset) => {

                if ( !dataset )
                {
                    return throwError('Could not find dataset with id of ' + id + '!');
                }

                return of(dataset);
            })
        );
    }

    /**
     * Preloads dataset$ with the dataset for the given ID, if such a dataset exists in datasets$
     * @param id
     */
    preloadDataset(id: string) {
        if (this._datasets.value) {
            this._dataset.next(this._datasets.value.find(dataset => dataset.id === id));
        }
    }

    async upsertDataset(dataset: Dataset, newDataset: boolean): Promise<Dataset | string> {
        const orgIdOverride = this._authService.organisationIdOverride$.value;
        const orgOverrideQuery = orgIdOverride ? `organisation:"${orgIdOverride}" ` : '';

        const idSection = newDataset ? '' : `id:"${dataset.id}" `;

        let encodedData = JSON.stringify(dataset.data).slice(1, -1);
        const operation = newDataset ? 'insert' : 'update';
        return await this._httpClient.post<{data: {insert: Dataset, update: Dataset}, errors: [{ statusCode, error, message }]}>(environment.config.endpoints.resources.url, {
            query: `mutation{${operation}(${idSection}${orgOverrideQuery}resource:{name:"${dataset.name}",data:"${encodedData}",slug:"${dataset.slug}",status:"${dataset.status}"} kind:"dataset"){id name data slug status }}`
        }).toPromise().then((datasetData) => {
            const newOrUpdatedDataset = newDataset ? datasetData.data.insert : datasetData.data.update;
            if (newOrUpdatedDataset) {
                return newOrUpdatedDataset;
            }
            return datasetData.errors[0].message;
        }).catch(() => {
            return null;
        })
    }

    async deleteDataset(dataset: Dataset): Promise<Dataset | string> {
        const orgIdOverride = this._authService.organisationIdOverride$.value;
        const orgOverrideQuery = orgIdOverride ? `organisation:"${orgIdOverride}" ` : '';

        return await this._httpClient.post<{data: {delete: Dataset}, errors: [{ statusCode, error, message }]}>(environment.config.endpoints.resources.url, {
            query: `mutation{delete(id:"${dataset.id}" ${orgOverrideQuery}kind:"dataset"){id status }}`
        }).toPromise().then((datasetData) => {
            const deletedDataset = datasetData.data.delete;
            if (deletedDataset) {
                return deletedDataset;
            }
            return datasetData.errors[0].message;
        }).catch(() => {
            return null;
        })
    }


    // HEADERS

    async getHeaders(): Promise<Header[]> {
        const orgIdOverride = this._authService.organisationIdOverride$.value;
        const orgOverrideQuery = orgIdOverride ? `organisation:"${orgIdOverride}" ` : '';

        const asyncResult = await this._httpClient.post<{ data: { list: Header[] } }>(environment.config.endpoints.resources.url, {
            query: `query{list(${orgOverrideQuery}kind:"header"){id name slug status}}`
        }).toPromise();

        let headers = asyncResult.data.list;

        this._headers.next(headers);
        return headers;
    }

    getHeaderById(id: string): Observable<Header>
    {
        const orgIdOverride = this._authService.organisationIdOverride$.value;
        const orgOverrideQuery = orgIdOverride ? `organisation:"${orgIdOverride}" ` : '';

        return this._httpClient.post<{data: {get: Header}}>(environment.config.endpoints.resources.url, {
            query: `query{get(id:\"${id}\" ${orgOverrideQuery}kind:"header"){id name data slug status}}`
        }).pipe(
            take(1),
            map((headerData) => {

                // Update the header
                this._header.next(headerData.data.get);

                // Return the header
                return headerData.data.get;
            }),
            switchMap((header) => {

                if ( !header )
                {
                    return throwError('Could not find header with id of ' + id + '!');
                }

                return of(header);
            })
        );
    }

    /**
     * Preloads header$ with the header for the given ID, if such a header exists in headers$
     * @param id
     */
    preloadHeader(id: string) {
        if (this._headers.value) {
            this._header.next(this._headers.value.find(header => header.id === id));
        }
    }

    async upsertHeader(header: Header, newHeader: boolean): Promise<Header | string> {
        const orgIdOverride = this._authService.organisationIdOverride$.value;
        const orgOverrideQuery = orgIdOverride ? `organisation:"${orgIdOverride}" ` : '';

        const idSection = newHeader ? '' : `id:"${header.id}" `;

        let encodedData = JSON.stringify(header.data).slice(1, -1);
        const operation = newHeader ? 'insert' : 'update';
        return await this._httpClient.post<{data: {insert: Header, update: Header}, errors: [{ statusCode, error, message }]}>(environment.config.endpoints.resources.url, {
            query: `mutation{${operation}(${idSection}${orgOverrideQuery}resource:{name:"${header.name}",data:"${encodedData}",slug:"${header.slug}",status:"${header.status}"} kind:"header"){id name data slug status }}`
        }).toPromise().then((headerData) => {
            const newOrUpdatedHeader = newHeader ? headerData.data.insert : headerData.data.update;
            if (newOrUpdatedHeader) {
                return newOrUpdatedHeader;
            }
            return headerData.errors[0].message;
        }).catch(() => {
            return null;
        })
    }

    async deleteHeader(header: Header): Promise<Header | string> {
        const orgIdOverride = this._authService.organisationIdOverride$.value;
        const orgOverrideQuery = orgIdOverride ? `organisation:"${orgIdOverride}" ` : '';

        return await this._httpClient.post<{data: {delete: Header}, errors: [{ statusCode, error, message }]}>(environment.config.endpoints.resources.url, {
            query: `mutation{delete(id:"${header.id}" ${orgOverrideQuery}kind:"header"){id status }}`
        }).toPromise().then((headerData) => {
            const deletedHeader = headerData.data.delete;
            if (deletedHeader) {
                return deletedHeader;
            }
            return headerData.errors[0].message;
        }).catch(() => {
            return null;
        })
    }



    // STYLES

    async getStyles(): Promise<Style[]> {
        const orgIdOverride = this._authService.organisationIdOverride$.value;
        const orgOverrideQuery = orgIdOverride ? `organisation:"${orgIdOverride}" ` : '';

        const asyncResult = await this._httpClient.post<{ data: { list: Style[] } }>(environment.config.endpoints.resources.url, {
            query: `query{list(${orgOverrideQuery}kind:"style"){id name slug status}}`
        }).toPromise();

        let styles = asyncResult.data.list;
        this._styles.next(styles);
        return styles;
    }

    getStyleById(id: string): Observable<Style>
    {
        const orgIdOverride = this._authService.organisationIdOverride$.value;
        const orgOverrideQuery = orgIdOverride ? `organisation:"${orgIdOverride}" ` : '';

        return this._httpClient.post<{data: {get: Style}}>(environment.config.endpoints.resources.url, {
            query: `query{get(id:\"${id}\" ${orgOverrideQuery}kind:"style"){id name data slug status}}`
        }).pipe(
            take(1),
            map((styleData) => {

                // Update the style
                this._style.next(styleData.data.get);

                // Return the style
                return styleData.data.get;
            }),
            switchMap((style) => {

                if ( !style )
                {
                    return throwError('Could not find style with id of ' + id + '!');
                }

                return of(style);
            })
        );
    }

    /**
     * Preloads style$ with the style for the given ID, if such a style exists in styles$
     * @param id
     */
    preloadStyle(id: string) {
        if (this._styles.value) {
            this._style.next(this._styles.value.find(style => style.id === id));
        }
    }

    async upsertStyle(style: Style, newStyle: boolean): Promise<Style | string> {
        const orgIdOverride = this._authService.organisationIdOverride$.value;
        const orgOverrideQuery = orgIdOverride ? `organisation:"${orgIdOverride}" ` : '';

        const idSection = newStyle ? '' : `id:"${style.id}" `;

        let encodedData = JSON.stringify(style.data).slice(1, -1);
        const operation = newStyle ? 'insert' : 'update';
        return await this._httpClient.post<{data: {insert: Style, update: Style}, errors: [{ statusCode, error, message }]}>(environment.config.endpoints.resources.url, {
            query: `mutation{${operation}(${idSection}${orgOverrideQuery}resource:{name:"${style.name}",data:"${encodedData}",slug:"${style.slug}",status:"${style.status}"} kind:"style"){id name data slug status }}`
        }).toPromise().then((styleData) => {
            const newOrUpdatedStyle = newStyle ? styleData.data.insert : styleData.data.update;
            if (newOrUpdatedStyle) {
                return newOrUpdatedStyle;
            }
            return styleData.errors[0].message;
        }).catch(() => {
            return null;
        })
    }

    async deleteStyle(style: Style): Promise<Style | string> {
        const orgIdOverride = this._authService.organisationIdOverride$.value;
        const orgOverrideQuery = orgIdOverride ? `organisation:"${orgIdOverride}" ` : '';

        return await this._httpClient.post<{data: {delete: Style}, errors: [{ statusCode, error, message }]}>(environment.config.endpoints.resources.url, {
            query: `mutation{delete(id:"${style.id}" ${orgOverrideQuery}kind:"style"){id status }}`
        }).toPromise().then((styleData) => {
            const deletedStyle = styleData.data.delete;
            if (deletedStyle) {
                return deletedStyle;
            }
            return styleData.errors[0].message;
        }).catch(() => {
            return null;
        })
    }



    // DISTRIBUTION LISTS

    async getDistlists(): Promise<Distlist[]> {
        const orgIdOverride = this._authService.organisationIdOverride$.value;
        const orgOverrideQuery = orgIdOverride ? `organisation:"${orgIdOverride}" ` : '';

        const asyncResult = await this._httpClient.post<{ data: { list: Distlist[] } }>(environment.config.endpoints.resources.url, {
            query: `query{list(${orgOverrideQuery}kind:"distribution"){id name slug status}}`
        }).toPromise();

        let distlists = asyncResult.data.list;

        this._distlists.next(distlists);
        return distlists;
    }

    getDistlistById(id: string): Observable<Distlist>
    {
        const orgIdOverride = this._authService.organisationIdOverride$.value;
        const orgOverrideQuery = orgIdOverride ? `organisation:"${orgIdOverride}" ` : '';

        return this._httpClient.post<{data: {get: Distlist}}>(environment.config.endpoints.resources.url, {
            query: `query{get(id:\"${id}\" ${orgOverrideQuery}kind:"distribution"){id name data slug status}}`
        }).pipe(
            take(1),
            map((distlistData) => {

                // Update the distlist
                this._distlist.next(distlistData.data.get);

                // Return the distlist
                return distlistData.data.get;
            }),
            switchMap((distlist) => {

                if ( !distlist )
                {
                    return throwError('Could not find distlist with id of ' + id + '!');
                }

                return of(distlist);
            })
        );
    }

    /**
     * Preloads distlist$ with the distlist for the given ID, if such a distlist exists in distlists$
     * @param id
     */
    preloadDistlist(id: string) {
        if (this._distlists.value) {
            this._distlist.next(this._distlists.value.find(distlist => distlist.id === id));
        }
    }

    async upsertDistlist(distlist: Distlist, newDistlist: boolean): Promise<Distlist | string> {
        const orgIdOverride = this._authService.organisationIdOverride$.value;
        const orgOverrideQuery = orgIdOverride ? `organisation:"${orgIdOverride}" ` : '';

        const idSection = newDistlist ? '' : `id:"${distlist.id}" `;

        let encodedData = JSON.stringify(distlist.data).slice(1, -1);
        const operation = newDistlist ? 'insert' : 'update';
        return await this._httpClient.post<{data: {insert: Distlist, update: Distlist}, errors: [{ statusCode, error, message }]}>(environment.config.endpoints.resources.url, {
            query: `mutation{${operation}(${idSection}${orgOverrideQuery}resource:{name:"${distlist.name}",data:"${encodedData}",slug:"${distlist.slug}",status:"${distlist.status}"} kind:"distribution"){id name data slug status }}`
        }).toPromise().then((distlistData) => {
            const newOrUpdatedDistlist = newDistlist ? distlistData.data.insert : distlistData.data.update;
            if (newOrUpdatedDistlist) {
                return newOrUpdatedDistlist;
            }
            return distlistData.errors[0].message;
        }).catch(() => {
            return null;
        })
    }

    async deleteDistlist(distlist: Distlist): Promise<Distlist | string> {
        const orgIdOverride = this._authService.organisationIdOverride$.value;
        const orgOverrideQuery = orgIdOverride ? `organisation:"${orgIdOverride}" ` : '';

        return await this._httpClient.post<{data: {delete: Distlist}, errors: [{ statusCode, error, message }]}>(environment.config.endpoints.resources.url, {
            query: `mutation{delete(id:"${distlist.id}" ${orgOverrideQuery}kind:"distribution"){id status }}`
        }).toPromise().then((distlistData) => {
            const deletedDistlist = distlistData.data.delete;
            if (deletedDistlist) {
                return deletedDistlist;
            }
            return distlistData.errors[0].message;
        }).catch(() => {
            return null;
        })
    }


    // PROCESSES

    // DISTRIBUTION LISTS

    async getProcesses(): Promise<Process[]> {
        const orgIdOverride = this._authService.organisationIdOverride$.value;
        const orgOverrideQuery = orgIdOverride ? `organisation:"${orgIdOverride}" ` : '';

        const asyncResult = await this._httpClient.post<{ data: { list: Process[] } }>(environment.config.endpoints.resources.url, {
            query: `query{list(${orgOverrideQuery}kind:"process"){id name slug status}}`
        }).toPromise();

        let processes = asyncResult.data.list;

        this._processes.next(processes);
        return processes;
    }

    getProcessById(id: string): Observable<Process>
    {
        const orgIdOverride = this._authService.organisationIdOverride$.value;
        const orgOverrideQuery = orgIdOverride ? `organisation:"${orgIdOverride}" ` : '';

        return this._httpClient.post<{data: {get: Process}}>(environment.config.endpoints.resources.url, {
            query: `query{get(id:\"${id}\" ${orgOverrideQuery}kind:"process"){id name data slug }}`
        }).pipe(
            take(1),
            map((processData) => {

                // Update the process
                this._process.next(processData.data.get);

                // Return the process
                return processData.data.get;
            }),
            switchMap((process) => {

                if ( !process )
                {
                    return throwError('Could not find process with id of ' + id + '!');
                }

                return of(process);
            })
        );
    }

    /**
     * Preloads process$ with the process for the given ID, if such a process exists in processes$
     * @param id
     */
    preloadProcess(id: string) {
        if (this._processes.value) {
            this._process.next(this._processes.value.find(process => process.id === id));
        }
    }

    async upsertProcess(process: Process, newProcess: boolean): Promise<Process | string> {
        const orgIdOverride = this._authService.organisationIdOverride$.value;
        const orgOverrideQuery = orgIdOverride ? `organisation:"${orgIdOverride}" ` : '';

        const idSection = newProcess ? '' : `id:"${process.id}" `;

        let encodedData = JSON.stringify(process.data).slice(1, -1);
        const operation = newProcess ? 'insert' : 'update';
        return await this._httpClient.post<{data: {insert: Process, update: Process}, errors: [{ statusCode, error, message }]}>(environment.config.endpoints.resources.url, {
            query: `mutation{${operation}(${idSection}${orgOverrideQuery}resource:{name:"${process.name}",data:"${encodedData}",slug:"${process.slug}"} kind:"process"){id name data slug }}`
        }).toPromise().then((processData) => {
            const newOrUpdatedProcess = newProcess ? processData.data.insert : processData.data.update;
            if (newOrUpdatedProcess) {
                return newOrUpdatedProcess;
            }
            return processData.errors[0].message;
        }).catch(() => {
            return null;
        })
    }

    async deleteProcess(process: Process): Promise<Process | string> {
        const orgIdOverride = this._authService.organisationIdOverride$.value;
        const orgOverrideQuery = orgIdOverride ? `organisation:"${orgIdOverride}" ` : '';

        return await this._httpClient.post<{data: {delete: Process}, errors: [{ statusCode, error, message }]}>(environment.config.endpoints.resources.url, {
            query: `mutation{delete(id:"${process.id}" ${orgOverrideQuery}kind:"process"){id status }}`
        }).toPromise().then((processData) => {
            const deletedProcess = processData.data.delete;
            if (deletedProcess) {
                return deletedProcess;
            }
            return processData.errors[0].message;
        }).catch(() => {
            return null;
        })
    }
}
