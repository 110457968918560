import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { InvitationCheck, InvitationResponse, User } from "../../core/user/user.types";
import { environment } from "../../../environments/environment";
import { AuthService } from "../../core/auth/auth.service";

@Injectable({
    providedIn: 'root'
})
export class OrgUsersService
{
    private _orgUser: BehaviorSubject<User | null> = new BehaviorSubject(null);
    private _orgUsers: BehaviorSubject<User[] | null> = new BehaviorSubject(null);

    constructor(
        private _authService: AuthService,
        private _httpClient: HttpClient)
    {
    }

    get orgUser(): User {
        return this._orgUser.value;
    }

    get orgUser$(): Observable<any>
    {
        return this._orgUser.asObservable();
    }

    get orgUsers$(): BehaviorSubject<User[]>
    {
        return this._orgUsers;
    }

    clearOrgUsersCache() {
        this._orgUsers.next([]);
        this._orgUser.next(null);
    }

    async getOrgUsers(): Promise<User[]> {
        const orgIdOverride = this._authService.organisationIdOverride$.value;
        const orgOverrideQuery = orgIdOverride ? `(organisation:"${orgIdOverride}") ` : '';

        const asyncResult = await this._httpClient.post<{ data: { list: User[] } }>(environment.config.endpoints.users.url, {
            query: `query{list${orgOverrideQuery}{id,name,email,disabled,roles {user, admin, api} invitation{id expires}}}`
        }).toPromise();

        let users = asyncResult.data.list;
        if (users) {
            users = users.sort((a, b) => {
                return (a.name ?? '').localeCompare(b.name ?? '');
            });
        }

        this._orgUsers.next(users);
        return users;
    }

    getOrgUserById(id: string): Observable<User>
    {
        return this._httpClient.post<{data: {get: User}}>(environment.config.endpoints.users.url, {
            query: `query{get(id:\"${id}\" ){email id name organisation permissions disabled roles {user, admin, api} invitation{id expires} }}`
        }).pipe(
            take(1),
            map((orgUserData) => {

                // Update the user
                this._orgUser.next(orgUserData.data.get);

                // Return the user
                return orgUserData.data.get;
            }),
            switchMap((user) => {

                if ( !user )
                {
                    return throwError('Could not find user with id of ' + id + '!');
                }

                return of(user);
            })
        );
    }

    /**
     * Preloads orgUser$ with the user for the given ID, if such a user exists in orgUsers$
     * @param id
     */
    preloadOrgUser(id: string) {
        if (this._orgUsers.value) {
            this._orgUser.next(this._orgUsers.value.find(user => user.id === id));
        }
    }

    async updateOrgUser(orgUser: User): Promise<User | string> {
        const disabledParam = orgUser.disabled ? ',disabled:true' : ''
        const invitationParam = orgUser.invitation ? `,invitation:{id:"${orgUser.invitation.id}",expires:"${orgUser.invitation.expires}"}` : '';
        return await this._httpClient.post<{data: {update: User}, errors: [{ statusCode, error, message }]}>(environment.config.endpoints.users.url, {
            query: `mutation{update(userData:{id:"${orgUser.id}",name:"${orgUser.name}",email:"${orgUser.email}",organisation:"${orgUser.organisation}",roles:{user:${orgUser.roles.user},admin:${orgUser.roles.admin},api:${orgUser.roles.api}}${disabledParam}${invitationParam}}){id firebaseId name email organisation roles{user admin api} disabled invitation{id expires} }}`
        }).toPromise().then((userData) => {
            const updatedUser = userData.data.update;
            if (updatedUser) {
                this._orgUser.next(updatedUser);
                return updatedUser;
            }
            return userData.errors[0].message;
        }).catch(() => {
            return null;
        })
    }

    // async insertOrgUser(newUser: User): Promise<User> {
    //     const disabledParam = newUser.disabled ? ',disabled:true' : '';
    //     return await this._httpClient.post<{data: {insert: User}}>(environment.config.endpoints.users.url, {
    //         query: `mutation{insert(userData:{id:"${newUser.id}",name:"${newUser.name}",email:"${newUser.email}",organisation:"${newUser.organisation}",roles:{user:${newUser.roles.user},admin:${newUser.roles.admin},api:${newUser.roles.api}}${disabledParam}}){name email organisation roles{user admin api} disabled }}`
    //     }).toPromise().then((userData) => {
    //         return userData.data.insert;
    //     }).catch(() => {
    //         return null;
    //     })
    // }

    async inviteOrgUser(newUser: User): Promise<InvitationResponse> {
        return await this._httpClient.post<{data: {invite: InvitationResponse[]}}>(environment.config.endpoints.users.url, {
            query: `mutation{invite(invitations: [{name:"${newUser.name}",email:"${newUser.email}",organisation:"${newUser.organisation}"}]){email success message invitation{id expires}}}`
        }).toPromise().then((inviteData) => {
            return inviteData.data.invite[0];
        }).catch(() => {
            return null;
        })
    }

    async deleteOrgUser(userId: string): Promise<User> {
        return await this._httpClient.post<{data: {delete: User}}>(environment.config.endpoints.users.url, {
            query: `mutation{delete(id:"${userId}"){id name email organisation roles{user admin api} disabled }}`
        }).toPromise().then((userData) => {
            const deletedUser = userData.data.delete;
            if (deletedUser.id) {
                this._orgUser.next(deletedUser);
            }
            return deletedUser;
        }).catch(() => {
            return null;
        })
    }

    async getOrgUserByEmail(email: string): Promise<User> {
        return await this._httpClient.post<{data: {get: User}}>(environment.config.endpoints.users.url, {
            query: `query{get(email:\"${email}\" ){email id name organisation permissions disabled roles {user, admin, api} invitation{id expires} }}`
        }).toPromise().then((userData) => {
            return userData.data.get;
        }).catch(() => {
            return null;
        })
    }
}
