import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { AdminGuard } from "./core/auth/guards/admin.guard";
import { ApiGuard } from "./core/auth/guards/api.guard";
import { AuthChangePasswordModule } from "./modules/auth/change-password/change-password.module";
import { SuperGuard } from "./core/auth/guards/super.guard";
import { SubmissionResolver } from "./modules/submissions/submissions.resolvers";
import { OrgNavResolver } from "./orgnav.resolvers";

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/dashboard'
    {path: '', pathMatch : 'full', redirectTo: 'dashboard'},

    // Redirect signed in user to the '/dashboard'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'dashboard'},

    // Auth routes for all
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
        ]
    },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            // {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
            // {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)}
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)}
        ]
    },

    // Auth routes
    {
        path       : '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component  : LayoutComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve  : {
            org : OrgNavResolver
        },
        children   : [

            {path: 'organisations', canActivate: [SuperGuard], loadChildren: () => import('app/modules/organisations/organisations.module').then(m => m.OrganisationsModule)},

            {path: 'dashboard', loadChildren: () => import('app/modules/dashboard/dashboard.module').then(m => m.DashboardModule)},
            {path: 'submissions', loadChildren: () => import('app/modules/submissions/submissions.module').then(m => m.SubmissionsModule)},
            {path: 'profile', loadChildren: () => import('app/modules/profile/profile.module').then(m => m.ProfileModule)},
            {path: 'change-password', loadChildren: () => import('app/modules/auth/change-password/change-password.module').then(m => m.AuthChangePasswordModule)},
            {path: 'users', canActivate: [AdminGuard], loadChildren: () => import('app/modules/orgUsers/orgUsers.module').then(m => m.OrgUsersModule)},
            {path: 'organisation', canActivate: [SuperGuard], loadChildren: () => import('app/modules/organisation/organisation.module').then(m => m.OrganisationModule)},
            {path: 'forms', canActivate: [SuperGuard], loadChildren: () => import('app/modules/forms/forms.module').then(m => m.FormsModule)},
            {path: 'files/images', canActivate: [SuperGuard], loadChildren: () => import('app/modules/files/images/images.module').then(m => m.ImagesModule)},
            {path: 'files/documents', canActivate: [SuperGuard], loadChildren: () => import('app/modules/files/documents/documents.module').then(m => m.DocumentsModule)},

            {path: 'userSearch', canActivate: [SuperGuard], loadChildren: () => import('app/modules/userSearch/userSearch.module').then(m => m.UserSearchModule)},
            {path: 'tasks', canActivate: [SuperGuard], loadChildren: () => import('app/modules/tasks/tasks.module').then(m => m.TasksModule)},
            {path: 'stucktasks', canActivate: [SuperGuard], loadChildren: () => import('app/modules/stuckTasks/stuckTasks.module').then(m => m.StuckTasksModule)},

            {path: 'resources/datasets', canActivate: [SuperGuard], loadChildren: () => import('app/modules/resources/datasets/datasets.module').then(m => m.DatasetsModule)},
            {path: 'resources/headers', canActivate: [SuperGuard], loadChildren: () => import('app/modules/resources/headers/headers.module').then(m => m.HeadersModule)},
            {path: 'resources/styles', canActivate: [SuperGuard], loadChildren: () => import('app/modules/resources/styles/styles.module').then(m => m.StylesModule)},
            {path: 'resources/distribution', canActivate: [SuperGuard], loadChildren: () => import('app/modules/resources/distlists/distlists.module').then(m => m.DistlistsModule)},
            {path: 'resources/processes', canActivate: [SuperGuard], loadChildren: () => import('app/modules/resources/processes/processes.module').then(m => m.ProcessesModule)},

            {path: 'api', canActivate: [ApiGuard], loadChildren: () => import('app/modules/api/api.module').then(m => m.ApiModule)},


            // Pages
            {path: 'pages', children: [

                    // // Coming Soon
                    // {path: 'coming-soon', loadChildren: () => import('app/modules/admin/pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule)},
                    //
                    // Error
                    {path: 'error', children: [
                            {path: '404', loadChildren: () => import('app/modules/pages/error/error-404/error-404.module').then(m => m.Error404Module)},
                            {path: '500', loadChildren: () => import('app/modules/pages/error/error-500/error-500.module').then(m => m.Error500Module)}
                        ]},

                    // // Maintenance
                    // {path: 'maintenance', loadChildren: () => import('app/modules/admin/pages/maintenance/maintenance.module').then(m => m.MaintenanceModule)},

                    // // Settings
                    // {path: 'settings', loadChildren: () => import('app/modules/admin/pages/settings/settings.module').then(m => m.SettingsModule)},
                ]},

            // 404 & Catch all
            {path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/pages/error/error-404/error-404.module').then(m => m.Error404Module)},
            {path: '**', redirectTo: '404-not-found'}
        ]
    }
];
