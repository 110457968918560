<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation"
    [opened]="!isScreenSmall"
    (openedChanged)="navigationOpenedChanged()">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center p-6 pb-0">
            <!-- Organisation image -->
            <img
                *ngIf="organisationLogoUrl"
                class="pl-5 pr-5"
                [src]="organisationLogoUrl"
                alt="Logo">

            <!-- Light version -->
            <img
                *ngIf="!organisationLogoUrl"
                class="dark:hidden"
                src="assets/images/logo/logo-text.png"
                alt="Logo">

            <!-- Dark version -->
            <img
                *ngIf="!organisationLogoUrl"
                class="hidden dark:flex"
                src="assets/images/logo/logo-text-on-dark.png"
                alt="Logo">
        </div>
    </ng-container>
    <!-- Navigation footer hook -->
    <ng-container fuseVerticalNavigationFooter>
        <div class="flex flex-0 items-center justify-center h-16 pr-6 pl-2 mt-2 mb-0">
            <a href="https://play.google.com/store/apps/details?id=com.nuftech.nuftechforms" target="_blank">
                <img
                class="max-w-36"
                src="assets/images/banners/google_play_badge.png"
                alt="Rugged Data">
            </a>
        </div>
        <div class="flex flex-1 items-center justify-center pr-6 pl-2 mb-4 text-sm">Hub v{{ version }}</div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <!-- Taskbar Text -->
        <div class="flex pl-7 opacity-80" style="font-size: 18px; font-weight: 600;"> <!-- text-primary-400 -->
            {{ buildEnv }}
        </div>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-1 sm:space-x-2">
<!--            <languages></languages>-->
            <button
                *ngIf="userIsSuper"
                mat-icon-button
                #tooltip="matTooltip"
                [matTooltip]="hasJustCopiedShareLink ? ('Copied' | transloco) : ('Get Share Link' | transloco)"
                (mouseenter)="hasJustCopiedShareLink = false"
                (touchstart)="hasJustCopiedShareLink = false"
                (click)="getShareLink(); tooltip.show()">
                <mat-icon [svgIcon]="'heroicons_outline:link'"></mat-icon>
            </button>
            <fuse-fullscreen [tooltip]="'Toggle Fullscreen' | transloco" class="hidden md:block"></fuse-fullscreen>
            <button
                mat-icon-button
                [matTooltip]="schemeTooltip | transloco"
                (click)="toggleUserTheme()">
                <mat-icon [svgIcon]="schemeIcon"></mat-icon>
            </button>

<!--            <search [appearance]="'bar'"></search>-->
<!--            <shortcuts></shortcuts>-->
<!--            <messages></messages>-->
<!--            <notifications></notifications>-->
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Rugged Data &copy; {{currentYear}}</span>
    </div>

</div>
