import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import {
    FuseNavigationItem,
    FuseNavigationService,
    FuseVerticalNavigationComponent
} from '@fuse/components/navigation';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { environment } from "../../../../../environments/environment";
import { Scheme } from "../../../../core/config/app.config";
import { FuseConfigService } from "../../../../../@fuse/services/config";
import { AuthService } from "../../../../core/auth/auth.service";
import { OrganisationsService } from "../../../../modules/organisations/organisations.service";

@Component({
    selector     : 'classic-layout',
    templateUrl  : './classic.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ClassicLayoutComponent implements OnInit, OnDestroy
{
    isScreenSmall: boolean;
    navigation: FuseNavigationItem[];
    version: string = environment.appVersion;
    organisationLogoUrl: string;
    schemeIcon: string;
    scheme: Scheme;
    schemeTooltip: string;
    hasJustCopiedShareLink: boolean;
    userIsSuper: boolean;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _authService: AuthService,
        private _organisationsService: OrganisationsService,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseConfigService: FuseConfigService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    get buildEnv(): string
    {
        return environment.buildEnv;
    }

    ngOnInit(): void
    {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: FuseNavigationItem[]) => {
                this.navigation = navigation;
            });

        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

        this._organisationsService.organisationLogoUrl$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((organisationLogoUrl: string) => {
                this.organisationLogoUrl = organisationLogoUrl;
            });

        this.updateUserTheme(this._authService.userTheme === 'dark' ? 'dark' : 'light');

        this.userIsSuper = this._authService.getIsSuperAdmin();
    }

    ngOnDestroy(): void
    {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    toggleNavigation(name: string): void
    {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if ( navigation )
        {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    navigationOpenedChanged() {
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 400);
    }

    /**
     * Update the user scheme
     *
     * @param scheme
     */
    updateUserTheme(scheme: Scheme): void
    {
        this.scheme = scheme;
        this._fuseConfigService.config = {scheme};
        this.updateSchemeIcon(scheme);
        this._authService.userTheme = scheme;
    }

    toggleUserTheme() {
        this.updateUserTheme(this.scheme === 'light' ? 'dark' : 'light');
    }

    updateSchemeIcon(scheme: Scheme) {
        this.schemeIcon = scheme === 'dark' ? 'heroicons_solid:sun' : scheme === 'light' ? 'heroicons_solid:moon' : 'heroicons_solid:lightning-bolt';
        this.schemeTooltip = scheme === 'dark' ? 'Light Mode' : scheme === 'light' ? 'Dark Mode' : '';
        // this.schemeIcon = scheme === 'auto' ? 'heroicons_solid:lightning-bolt' : scheme === 'dark' ? 'heroicons_solid:moon' : 'heroicons_solid:sun';
    }

    getShareLink() {
        this.hasJustCopiedShareLink = true;
        const url = new URL(window.location.href);

        url.searchParams.append('organisation', this._authService.getOrganisationId());

        const textArea = document.createElement("textarea");
        textArea.value = url.toString();
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("Copy");
        textArea.remove();
    }
}
