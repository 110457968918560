<!-- Button -->
<button
    mat-icon-button
    [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img
            class="w-7 h-7 rounded-full"
            *ngIf="avatar"
            [src]="avatar"
            alt="User Image"
            referrerpolicy="no-referrer"
            (error)="avatar=null">
        <mat-icon
            *ngIf="!avatar"
            [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    </span>
</button>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
    <div class="p-4">
        <span class="flex flex-col leading-none">
            <span>{{'Signed in as' | transloco}}</span>
            <span class="mt-1.5 text-md font-medium">{{user?.email}}</span>
        </span>
    </div>
    <mat-divider class="my-2"></mat-divider>
    <a [routerLink]="'/profile'">
        <button mat-menu-item>
            <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
            <span>{{'Profile' | transloco}}</span>
        </button>
    </a>
<!--    <button mat-menu-item>-->
<!--        <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>-->
<!--        <span>{{'Settings' | transloco}}</span>-->
<!--    </button>-->
<!--    <button-->
<!--        mat-menu-item-->
<!--        [matMenuTriggerFor]="userTheme">-->
<!--        <mat-icon svgIcon="{{ schemeIcon }}"></mat-icon>-->
<!--        <span>{{'Theme' | transloco}}</span>-->
<!--    </button>-->
    <mat-divider class="my-2"></mat-divider>
    <button
        mat-menu-item
        (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>{{'Sign out' | transloco}}</span>
    </button>
</mat-menu>

<!--<mat-menu-->
<!--    class="user-theme-menu"-->
<!--    #userTheme="matMenu">-->
<!--&lt;!&ndash;    <button mat-menu-item (click)="updateUserTheme('auto')">&ndash;&gt;-->
<!--&lt;!&ndash;        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:lightning-bolt'"></mat-icon>&ndash;&gt;-->
<!--&lt;!&ndash;        <span>{{'Auto' | transloco}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;    </button>&ndash;&gt;-->
<!--    <button mat-menu-item (click)="updateUserTheme('light')">-->
<!--        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:sun'"></mat-icon>-->
<!--        <span>{{'Light' | transloco}}</span>-->
<!--    </button>-->
<!--    <button mat-menu-item (click)="updateUserTheme('dark')">-->
<!--        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:moon'"></mat-icon>-->
<!--        <span>{{'Dark' | transloco}}</span>-->
<!--    </button>-->
<!--</mat-menu>-->
