import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from "../../core/auth/auth.service";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { tap } from "rxjs/operators";
import { FormTemplate, ReportDefinition, ReportGeneratorTemplate } from './forms.types';
import { User } from "../../core/user/user.types";

@Injectable({
    providedIn: 'root'
})
export class FormsService
{
    private _form: BehaviorSubject<FormTemplate | null> = new BehaviorSubject(null);
    private _forms: BehaviorSubject<FormTemplate[] | null> = new BehaviorSubject(null);

    get form$(): Observable<FormTemplate>
    {
        return this._form.asObservable();
    }

    get forms$(): BehaviorSubject<FormTemplate[]>
    {
        return this._forms;
    }

    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthService)
    {
    }

    /**
     * Preloads form$ with the form for the given ID, if such a form exists in forms$
     * @param id
     */
    preloadForm(id: string) {
        if (this._forms) {
            this._form.next(this._forms.value?.find(form => form.id === id));
        }
    }

    getForms(): Observable<{ data: { list: FormTemplate[] } }>
    {
        const orgIdOverride = this._authService.organisationIdOverride$.value;
        const orgOverrideQuery = orgIdOverride ? `(organisation:"${orgIdOverride}") ` : '';

        return this._httpClient.post<{ data: { list: FormTemplate[] } }>(environment.config.endpoints.forms.url, {
            query: `query{list${orgOverrideQuery}{id name status definition }}`
        }).pipe(
            tap((response) => {
                // Sort alphabetically and store result
                this._forms.next(response.data.list.filter(form => form.id).sort((a, b) => {
                    return (a.name ?? '').localeCompare(b.name ?? '');
                }));
            })
        );
    }

    async getFormById(formId: string): Promise<FormTemplate> {
        const orgIdOverride = this._authService.organisationIdOverride$.value;
        const orgOverrideQuery = orgIdOverride ? `organisation:"${orgIdOverride}" ` : '';

        const asyncResult = await this._httpClient.post<{ data: { get: FormTemplate } }>(environment.config.endpoints.forms.url, {
            query: `query{get(id:"${formId}" ${orgOverrideQuery}){id name status definition resources body updated }}`
        }).toPromise();

        this._form.next(asyncResult.data.get);

        return asyncResult.data.get;
    }

    async deleteForm(formId: string): Promise<boolean | string> {
        const orgIdOverride = this._authService.organisationIdOverride$.value;
        const orgOverrideQuery = orgIdOverride ? `organisation:"${orgIdOverride}" ` : '';

        return await this._httpClient.post<{data: {delete: FormTemplate}}>(environment.config.endpoints.forms.url, {
            query: `mutation{delete(id:"${formId}" ${orgOverrideQuery}){id }}`
        }).toPromise().then((formData) => {
            return !!formData.data.delete
        }).catch(() => {
            return false;
        })
    }

    async upsertForm(updatedForm: FormTemplate, newForm: boolean): Promise<FormTemplate | string> {
        const orgIdOverride = this._authService.organisationIdOverride$.value;
        const orgOverrideQuery = orgIdOverride ? `organisation:"${orgIdOverride}" ` : '';

        const encodedResources = JSON.stringify(updatedForm.resources).slice(1, -1);
        const encodedBody = JSON.stringify(updatedForm.body).slice(1, -1);

        return await this._httpClient.post<{data: {upsert: FormTemplate}, errors: [{ statusCode, error, message }]}>(environment.config.endpoints.forms.url, {
            query: `mutation{upsert(id:"${updatedForm.id}" ${orgOverrideQuery}form:{id:"${updatedForm.id}",name:"${updatedForm.name}",definition:"${updatedForm.definition}",status:"${updatedForm.status}",updated:"${updatedForm.updated}",resources:"{}",body:"${encodedBody}"}){id name key definition version status updated params{name, value} resources body }}`
        }).toPromise().then((formData) => {
            const updatedForm = formData.data.upsert;
            if (updatedForm) {
                this._form.next(updatedForm);
                return updatedForm;
            }
            return formData.errors[0].message;
        }).catch(() => {
            return null;
        })
    }

    async getReportDefinition(formId: string): Promise<ReportDefinition> {
        const orgIdOverride = this._authService.organisationIdOverride$.value;
        const orgOverrideQuery = orgIdOverride ? `organisation:"${orgIdOverride}" ` : '';

        const asyncResult = await this._httpClient.post<{ data: { list: ReportDefinition } }>(environment.config.endpoints.reportDefinitions.url, {
            query: `query{list(form:"${formId}" ${orgOverrideQuery}){id formId definition coverPDF tailPDF templateId version status overrideCSS }}`
        }).toPromise();

        // this._reportDefinition.next(asyncResult.data.get);

        return asyncResult.data.list[0];
    }

    async getReportTemplate(): Promise<ReportGeneratorTemplate> {
        const asyncResult = await this._httpClient.post<{ data: { template: ReportGeneratorTemplate } }>(environment.config.endpoints.reportGenerator.url, {
            query: `query{template(id:"default"){name html }}`
        }).toPromise();

        return asyncResult.data.template;
    }

    // TODO make this work
    /** Updates the coverPDF, tailPDF, and overrideCSS of the report definition. */
    async updateReportDefinition(updatedReportDef: ReportDefinition): Promise<ReportDefinition | string> {
        const orgIdOverride = this._authService.organisationIdOverride$.value;
        const orgOverrideQuery = orgIdOverride ? `organisation:"${orgIdOverride}" ` : '';

        // const encodedDefinition = JSON.stringify(updatedReportDefinition.definition).slice(1, -1);
        console.dir(updatedReportDef);
        let requestQuery = `mutation{update(organisation:"${this._authService.getOrganisationId()}" `;
        requestQuery+= `definitionData:{form:${updatedReportDef.form}, templateId:"${updatedReportDef.templateId}", status:"${updatedReportDef.status}", version:"${updatedReportDef.version}", definition:${updatedReportDef.definition}, id:"${updatedReportDef.id}", formId:"${updatedReportDef.formId}", `;
        if (updatedReportDef.coverPDF && updatedReportDef.coverPDF != 'undefined' && updatedReportDef.coverPDF != 'null')
            requestQuery+= ` coverPDF:"${updatedReportDef.coverPDF}" `;
        if (updatedReportDef.coverPDF && updatedReportDef.tailPDF != 'undefined' && updatedReportDef.tailPDF != 'null')
            requestQuery+= ` tailPDF:"${updatedReportDef.tailPDF}" `;
        requestQuery+= `overrideCSS:"${updatedReportDef.overrideCSS}"`;
        return await this._httpClient.post<{data: {update: ReportDefinition}, errors: [{ statusCode, error, message }]}>(environment.config.endpoints.reportDefinitions.url, {
            query: requestQuery+` }){id formId definition coverPDF tailPDF templateId version status overrideCSS }} `
        }).toPromise().then((definitionData) => {
            const updatedReportDefinition = definitionData.data.update;
            if (updatedReportDefinition) {
                //this._.next(updatedReportDefinition);
                return updatedReportDefinition;
            }
            return definitionData.errors[0].message;
        }).catch(() => {
            return null;
        })
    }

    async getPreviewUrl(formId: string, organisationId: string): Promise<string> {
        // TODO
        // const previewResponse = await this._httpClient.post<{data: {get: { url: string }}}>(environment.config.endpoints.reportGenerator.url, {
        //     query: `query{get(organisation:"${organisationId}" id:"${formId}" version:"default" ){ url name }}`
        // }).toPromise();
        //
        // return previewResponse.data.get.url;

        return null;
    }
}
