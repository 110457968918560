import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, from, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { AngularFireAuth } from "@angular/fire/auth";
import firebase from "firebase/app";
import 'firebase/auth';
import { InvitationCheck, Organisation, User } from "../user/user.types";
import { first } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { Md5 } from "ts-md5";
import { OrganisationsService } from "../../modules/organisations/organisations.service";

@Injectable()
export class AuthService
{
    private _user: BehaviorSubject<User> = new BehaviorSubject<User>(null);
    userAvatar$: BehaviorSubject<string> = new BehaviorSubject<string>(AuthService.localStorageGetItem('userAvatar'));
    userAvatarBlob$: BehaviorSubject<string> = new BehaviorSubject<string>(AuthService.localStorageGetItem('userAvatarBlob'));
    // private _organisation: BehaviorSubject<Organisation | null> = new BehaviorSubject(null);
    // private _organisations: BehaviorSubject<Organisation[] | null> = new BehaviorSubject(null);
    // organisationIdOverride$: BehaviorSubject<string> = new BehaviorSubject<string>(AuthService.localStorageGetItem('organisationIdOverride'));
    // organisationName$: BehaviorSubject<string> = new BehaviorSubject<string>(AuthService.localStorageGetItem('organisationName'));
    // organisationLogoUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(AuthService.localStorageGetItem('organisationLogoUrl'));

    onSignIn: Subject<any> = new Subject<any>();
    onSignOut: Subject<any> = new Subject<any>();

    public authenticated: boolean = false;

    constructor(
        public auth: AngularFireAuth,
        private _organisationsService: OrganisationsService,
        private _httpClient: HttpClient
    )
    {
    }

    static localStorageSetItem(key: string, value: string): void {
        try {
            localStorage.setItem(key, value);
        }
        catch { }
    }

    static localStorageGetItem(key: string): string {
        try {
            return localStorage.getItem(key);
        }
        catch { }
        return null;
    }

    static localStorageRemoveItem(key: string): void {
        try {
            return localStorage.removeItem(key);
        }
        catch { }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User)
    {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User>
    {
        return this._user.asObservable();
    }

    get user(): User {
        return this._user.value;
    }

    //

    get organisationIdOverride$(): BehaviorSubject<string>
    {
        return this._organisationsService.organisationIdOverride$;
    }


    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        AuthService.localStorageSetItem('accessToken', token);
    }

    get accessToken(): string
    {
        return AuthService.localStorageGetItem('accessToken') ?? '';
    }

    /**
     * Setter & getter for user theme
     */
    set userTheme(token: string)
    {
        AuthService.localStorageSetItem('userTheme', token);
    }

    get userTheme(): string
    {
        return AuthService.localStorageGetItem('userTheme') ?? '';
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        this.onSignOut.next(null);

        // Remove the access token from the local storage
        AuthService.localStorageRemoveItem('accessToken');

        // todo move these as constants somewhere
        AuthService.localStorageRemoveItem('filtersCachedTime');
        AuthService.localStorageRemoveItem('filterFormId');
        AuthService.localStorageRemoveItem('filterUserEmail');
        AuthService.localStorageRemoveItem('filterStartDate');
        AuthService.localStorageRemoveItem('filterEndDate');
        AuthService.localStorageRemoveItem('filterAdditionals');

        AuthService.localStorageRemoveItem('userTheme');
        AuthService.localStorageRemoveItem('organisationName');

        AuthService.localStorageRemoveItem('userAvatar');
        AuthService.localStorageRemoveItem('userAvatarBlob');

        AuthService.localStorageRemoveItem('organisationIdOverride');
        AuthService.localStorageRemoveItem('organisationName');
        AuthService.localStorageRemoveItem('organisationLogoUrl');


        // Set the authenticated flag to false
        this.authenticated = false;

        return from(this.auth.signOut());
    }

    forgotPassword(email: string): Observable<any>
    {
        return from(this.auth.sendPasswordResetEmail(email));
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        return from(this.checkAuth());
    }

    async checkAuth(): Promise<boolean> {
        if (this.authenticated && !AuthUtils.isTokenExpired(this.accessToken)){
            return true;
        }

        const currentUser = await this.getFirebaseUser();

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken) && currentUser) {
            await currentUser.getIdTokenResult(true).then((idTokenResult) => {
                this.accessToken = idTokenResult.token;
            });
        }

        // If user is now authenticated with firebase
        if (currentUser && !AuthUtils.isTokenExpired(this.accessToken)) {
            // Check authentication with RD
            if (this.authenticated) {
                return true;
            }

            return this.getRdUser(false).then((user) => {
                if (user && !user.disabled) {
                    this.onSignIn.next(null);
                    return this.authenticated = true;
                }
                // If the user is not found or is deleted, sign out of firebase
                this.signOut();
                return false;
            })
        }

        return false;
    }

    getFirebaseUser(): Promise<firebase.User> {
        return this.auth.authState.pipe(first()).toPromise();
    }

    /**
     * Check whether the current user has the admin role
     */
    async checkAdmin(): Promise<boolean>
    {
        const initialCheck = await this.checkAuth();
        if (!initialCheck) {
            return false;
        }

        return this.getIsAdmin();
    }

    getIsAdmin(): boolean {
        return this._user.value.roles?.super || this._user.value.roles?.admin;
    }

    /**
     * Check whether the current user has the API role
     */
    async checkApiAccess(): Promise<boolean>
    {
        const initialCheck = await this.checkAuth();
        if (!initialCheck) {
            return false;
        }

        return this.getHasApiAccess();
    }

    getHasApiAccess(): boolean {
        return this._user.value.roles?.super || this._user.value.roles?.api;
    }

    /**
     * Check whether the current user has the leadadmin role
     */
    async checkLeadAdmin(): Promise<boolean>
    {
        const initialCheck = await this.checkAuth();
        if (!initialCheck) {
            return false;
        }

        return this.getIsLeadAdmin();
    }

    getIsLeadAdmin(): boolean {
        return this._user.value.roles?.super;
    }

    /**
     * Check whether the current user has the super role
     */
    async checkSuperAdmin(): Promise<boolean>
    {
        const initialCheck = await this.checkAuth();
        if (!initialCheck) {
            return false;
        }

        return this.getIsSuperAdmin();
    }

    getIsSuperAdmin(): boolean {
        return this._user.value.roles?.super;
    }

    getRdUser(doNotRedirect: boolean): Promise<User> {
        return this._httpClient.post<{data: {get: User}}>(environment.config.endpoints.users.url, {
            query: `query{get{email id name organisation roles{user,admin,api,super} disabled }}`
        }, { params: doNotRedirect ? { dnr: true } : null })
        .toPromise()
        .then((userData) => {
            // if (userData.data.get.disabled) {
            //     return null;
            // }
            if (!userData.data.get.disabled) {
                this._organisationsService.getOrganisation();
            }
            return this.user = userData.data.get;
        }).catch(err => {
            return null;
        })
    }

    setAvatar(photoURL: string) {
        this.userAvatar$.next(photoURL);
        AuthService.localStorageSetItem('userAvatar', photoURL);
    }

    async setAvatarBlob(photoBlob: Blob) {
        const blobString = await AuthService.getBase64Image(photoBlob);
        this.userAvatarBlob$.next(blobString);
        AuthService.localStorageSetItem('userAvatarBlob', blobString);
    }

    getOrganisationId(): string {
        return this.organisationIdOverride$.value ?? this._user.value.organisation;
    }

    async checkInvitation(invitationId: string): Promise<InvitationCheck> {
        return await this._httpClient.post<{data: {checkInvitation: InvitationCheck}}>(environment.config.endpoints.users.url, {
            query: `query{checkInvitation(id:"${invitationId}"){success message user{name email organisation invitation{id expires}}}}`
        }, { params: { dnr: true } })
            .toPromise().then((checkInviteData) => {
                return checkInviteData.data.checkInvitation;
            }).catch(() => {
                return null;
            })
    }

    async acceptInvitation(invitationId: string): Promise<InvitationCheck>
    {
        return await this._httpClient.post<{data: {acceptInvitation: InvitationCheck}}>(environment.config.endpoints.users.url, {
            query: `mutation{acceptInvitation(id:"${invitationId}"){success message user{name email organisation}}}`
        }, { params: { dnr: true } })
            .toPromise().then((checkInviteData) => {
                return checkInviteData.data.acceptInvitation;
            }).catch(() => {
                return null;
            })
    }


    static getGravatarUrl(email: string): string {
        if (!email) return null;

        const hash = Md5.hashStr(email.toLowerCase());
        return `https://www.gravatar.com/avatar/${hash}?d=mp`;
    }

    static resizeAvatar(avatarUrl: string, px: Number): string {
        if (avatarUrl && avatarUrl.includes('gravatar.com')) {
            return `${avatarUrl}&s=${px}`;
        }
        return avatarUrl?.replace("s96-c", `s${px}-c`);
    }

    static getBase64Image(img: Blob): Promise<string> {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result as string);
            reader.readAsDataURL(img);
        });
    }
}
