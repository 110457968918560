import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { AngularFireModule } from "@angular/fire";
import { environment } from "../environments/environment";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { PdfJsViewerModule } from "ng2-pdfjs-viewer";
import { AgGridModule } from "ag-grid-angular";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { DATE_FORMATS } from "./core/formats/date-formats";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { PasswordStrengthMeterModule } from "angular-password-strength-meter";
import { PendingChangesGuard } from "./core/auth/guards/pending-changes.guard";

const routerConfig: ExtraOptions = {
    preloadingStrategy       : PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        AngularFireModule.initializeApp(environment.config.firebase),
        AngularFirestoreModule,
        AngularFireAuthModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        MatSnackBarModule,
        PasswordStrengthMeterModule,

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        //FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        // PDF Viewer
        PdfJsViewerModule,

        // AG Grid
        AgGridModule.withComponents([]),

        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({})
    ],
    bootstrap   : [
        AppComponent
    ],
    providers: [
        { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
        PendingChangesGuard
    ],
})
export class AppModule
{
}
