import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseDrawerModule } from '@fuse/components/drawer';
import { EmptyLayoutModule } from 'app/layout/layouts/empty/empty.module';
import { ClassicLayoutModule } from 'app/layout/layouts/vertical/classic/classic.module';
import { SharedModule } from 'app/shared/shared.module';

const layoutModules = [
  EmptyLayoutModule,
  ClassicLayoutModule,
];


@NgModule({
  declarations: [
    LayoutComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    FuseDrawerModule,
    SharedModule,
    ...layoutModules
  ],
  exports     : [
    LayoutComponent,
    ...layoutModules
  ]
})
export class LayoutModule { }
