import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Organisation } from "../../core/user/user.types";
import { environment } from "../../../environments/environment";
import { AuthService } from "../../core/auth/auth.service";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class OrganisationsService
{
    private _organisation: BehaviorSubject<Organisation | null> = new BehaviorSubject(null);
    private _organisations: BehaviorSubject<Organisation[] | null> = new BehaviorSubject(null);
    organisationIdOverride$: BehaviorSubject<string> = new BehaviorSubject<string>(AuthService.localStorageGetItem('organisationIdOverride'));
    organisationName$: BehaviorSubject<string> = new BehaviorSubject<string>(AuthService.localStorageGetItem('organisationName'));
    organisationLogoUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(AuthService.localStorageGetItem('organisationLogoUrl'));

    /**
     * Setter & getter for organisation
     *
     * @param value
     */
    set organisation(value: Organisation)
    {
        // Store the value
        this._organisation.next(value);

        AuthService.localStorageSetItem('organisationName', value.name);
        this.organisationName$.next(value.name);

        AuthService.localStorageSetItem('organisationLogoUrl', value.logoUrl);
        this.organisationLogoUrl$.next(value.logoUrl);
    }

    get organisation$(): Observable<Organisation>
    {
        return this._organisation.asObservable();
    }

    get organisation(): Organisation
    {
        return this._organisation.value;
    }

    get organisations$(): BehaviorSubject<Organisation[]>
    {
        return this._organisations;
    }


    constructor(
        private _httpClient: HttpClient)
    {

    }

    getOrganisation(): Promise<Organisation> {
        let query = this.organisationIdOverride$.value ? `query{get(id:"${this.organisationIdOverride$.value}"){id name logoUrl}}` : `query{get{id name logoUrl}}`;

        return this._httpClient.post<{data: {get: Organisation}}>(environment.config.endpoints.organisations.url, {
            query: query
        }).toPromise().then((orgData) => {
            return this.organisation = orgData.data.get;
        }).catch(err => {
            return null;
        })
    }
    //
    // getOrganisationId(): string {
    //     return this._authService.organisationIdOverride$.value ?? this._authService.user.organisation;
    // }

    async addOrganisation(name: string): Promise<Organisation | string> {
        return await this._httpClient.post<{data: {upsert: Organisation}, errors: [{ statusCode, error, message }]}>(environment.config.endpoints.organisations.url, {
            query: `mutation{upsert(organisation:{name:"${name}"}){id name }}`
        }).toPromise().then((orgData) => {
            const newOrg = orgData.data.upsert;
            if (newOrg) {
                return newOrg;
            }
            return orgData.errors[0].message;
        }).catch(() => {
            return null;
        })
    }

    async updateOrganisation(organisation: Organisation): Promise<Organisation | string> {
        return await this._httpClient.post<{data: {upsert: Organisation}, errors: [{ statusCode, error, message }]}>(environment.config.endpoints.organisations.url, {
            query: `mutation{upsert(id:"${organisation.id}",organisation:{id:"${organisation.id}",name:"${organisation.name}",logoUrl:"${organisation.logoUrl}"}){id name logoUrl }}`
        }).toPromise().then((orgData) => {
            const updatedOrg = orgData.data.upsert;
            if (updatedOrg) {
                this._organisation.next(updatedOrg);
                this.organisationName$.next(updatedOrg.name);
                this.organisationLogoUrl$.next(updatedOrg.logoUrl);
                return updatedOrg;
            }
            return orgData.errors[0].message;
        }).catch(() => {
            return null;
        })
    }

    async deleteOrganisation(orgId: string): Promise<boolean> {
        return await this._httpClient.post<{data: {delete: {message: string}}, errors: [{ statusCode, error, message }]}>(environment.config.endpoints.organisations.url, {
            query: `mutation{delete(id:"${orgId}"){message }}`
        }).toPromise().then((deleteData) => {
            return !!deleteData.data.delete.message;
        }).catch(() => {
            return false;
        })
    }

    getOrganisations(): Promise<Organisation[]> {
        return this._httpClient.post<{data: {list: Organisation[]}}>(environment.config.endpoints.organisations.url, {
            query: `query{list{id name logoUrl}}`
        }).toPromise().then((orgData) => {
            this.organisations$.next(orgData.data.list);
            return this._organisations;
        }).catch(err => {
            return null;
        })
    }

}
