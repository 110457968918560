import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import {
    FuseNavigationItem,
    FuseNavigationService,
    FuseVerticalNavigationComponent
} from "../../../@fuse/components/navigation";
import { AuthService } from "../auth/auth.service";
import { takeUntil } from "rxjs/operators";
import { OrganisationsService } from "../../modules/organisations/organisations.service";

@Injectable({
    providedIn: 'root'
})
export class NavigationService
{
    private _navigation: ReplaySubject<FuseNavigationItem[]> = new ReplaySubject<FuseNavigationItem[]>(1);

    constructor(
        private _httpClient: HttpClient,
        private _fuseNavigationService: FuseNavigationService,
        private _authService: AuthService,
        private _organisationsService: OrganisationsService)
    {
        this.setNavigation();
        this._organisationsService.organisationName$
            .subscribe((organisationName: string) => {
                this.updateOrganisationName(organisationName);
            });
    }

    async setNavigation() {
        this._navigation.next(this.getNavigationTree());
    }

    private getNavigationTree(): FuseNavigationItem[] {
        // The names for all of these are explicitly reset in language.component
        return [
            {
                id: 'main',
                title: AuthService.localStorageGetItem('organisationName') ?? ' ',
                type: 'group',
                children: [
                    {
                        id: 'main.dashboard',
                        title: 'Dashboard',
                        type: 'basic',
                        icon: 'heroicons_outline:view-grid',
                        link: '/dashboard'
                    },
                    {
                        id: 'main.submissions',
                        title: 'Submissions',
                        type: 'basic',
                        icon: 'heroicons_outline:document-text', // heroicons_outline:view-list
                        link: '/submissions',
                        // badge: {
                        //     title  : 'New',
                        //     classes: 'px-2.5 bg-green-400 text-black rounded-full'
                        // }
                    },
                    {
                        id: 'main.users',
                        title: 'Users',
                        type: 'basic',
                        icon: 'heroicons_outline:users',
                        link: '/users',
                        hidden: (item) => { return !this._authService.getIsAdmin(); }
                    },
                    {
                        id: 'main.forms',
                        title: 'Forms',
                        type: 'basic',
                        icon: 'heroicons_outline:clipboard-list',
                        link: '/forms',
                        hidden: (item) => { return !this._authService.getIsSuperAdmin(); }
                    },
                    {
                        id      : 'main.files',
                        title   : 'Files',
                        type    : 'collapsable',
                        icon    : 'heroicons_outline:folder-open',
                        hidden: (item) => { return !this._authService.getIsSuperAdmin(); },
                        children: [
                            {
                                id: 'main.files.images',
                                title: 'Images',
                                type: 'basic',
                                icon: 'heroicons_outline:photograph',
                                link: '/files/images',
                                hidden: (item) => { return !this._authService.getIsSuperAdmin(); }
                            },
                            {
                                id: 'main.files.documents',
                                title: 'Documents',
                                type: 'basic',
                                icon: 'heroicons_outline:document-duplicate',
                                link: '/files/documents',
                                hidden: (item) => { return !this._authService.getIsSuperAdmin(); }
                            },
                        ]
                    },
                    {
                        id      : 'main.resources',
                        title   : 'Resources',
                        type    : 'collapsable',
                        icon    : 'heroicons_outline:cube',
                        hidden: (item) => { return !this._authService.getIsSuperAdmin(); },
                        children: [
                            {
                                id   : 'main.resources.datasets',
                                title: 'Datasets',
                                type : 'basic',
                                icon : 'heroicons_outline:view-list',
                                link : '/resources/datasets'
                            },
                            {
                                id   : 'main.resources.headers',
                                title: 'Headers',
                                type : 'basic',
                                icon : 'heroicons_outline:menu-alt-4',
                                link : '/resources/headers'
                            },
                            {
                                id   : 'main.resources.styles',
                                title: 'Styles',
                                type : 'basic',
                                icon : 'heroicons_outline:color-swatch',
                                link : '/resources/styles'
                            },
                            {
                                id   : 'main.resources.distlists',
                                title: 'Distribution',
                                type : 'basic',
                                icon : 'heroicons_outline:mail',
                                link : '/resources/distribution'
                            },
                            {
                                id   : 'main.resources.processes',
                                title: 'Processes',
                                type : 'basic',
                                icon : 'heroicons_outline:dots-horizontal',
                                link : '/resources/processes'
                            }
                        ]
                    },
                    {
                        id: 'main.organisation',
                        title: 'Organisation',
                        type: 'basic',
                        icon: 'heroicons_outline:cog',
                        link: '/organisation',
                        hidden: (item) => { return !this._authService.getIsSuperAdmin(); }
                    },
                ]
            },
            {
                id: 'api',
                title: 'API',
                //subtitle: 'API Section',
                type: 'group',
                hidden: (item) => { return !this._authService.getHasApiAccess() },
                children: [
                    {
                        id: 'api.api',
                        title: 'API',
                        type: 'basic',
                        icon: 'heroicons_outline:globe-alt',
                        link: '/api'
                    }
                ]
            },
            {
                id  : 'divider-1',
                type: 'divider'
            },
            {
                id: 'admin',
                title: 'Admin',
                type: 'group',
                hidden: (item) => { return !this._authService.getIsSuperAdmin() },
                children: [
                    {
                        id: 'admin.organisations',
                        title: 'Switch Organisation',
                        type: 'basic',
                        icon: 'heroicons_outline:office-building',
                        link: '/organisations'
                    },
                    {
                        id: 'admin.userSearch',
                        title: 'User Search',
                        type: 'basic',
                        icon: 'heroicons_outline:search',
                        link: '/userSearch',
                    },
                    {
                        id      : 'admin.pendingTasks',
                        title   : 'Pending Tasks',
                        type    : 'collapsable',
                        icon    : 'heroicons_outline:cube',
                        children: [
                            {
                                id   : 'admin.pendingTasks.tasks',
                                title: 'Tasks',
                                type : 'basic',
                                icon : 'heroicons_outline:view-list',
                                link : '/tasks'
                            },
                            {
                                id   : 'admin.pendingTasks.stuckTasks',
                                title: 'Submitted',
                                type : 'basic',
                                icon : 'heroicons_outline:document-text',
                                link : '/stucktasks'
                            }
                        ]
                    },
                ]
            }
        ];
    }

    get navigation$(): Observable<FuseNavigationItem[]>
    {
        return this._navigation.asObservable();
    }

    private updateOrganisationName(organisationName: string) {
        // Get the component -> navigation data -> item
        const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');

        // Return if the navigation component does not exist
        if ( !navComponent ) {
            return null;
        }

        // Get the flat navigation data
        const navigation = navComponent.navigation;

        if (!navigation) return;

        // Update the Main Section title with the Organisation Name
        const mainSectionItem = this._fuseNavigationService.getItem('main', navigation);
        if (mainSectionItem) {
            mainSectionItem.title = organisationName;
            navComponent.refresh();
        }
    }
}
