import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { BehaviorSubject, Observable } from "rxjs";
import { QueuedFile, StorageObject } from './files.types';
import { tap } from "rxjs/operators";
import { AuthService } from 'app/core/auth/auth.service';
import { environment } from "../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class FilesService
{
    private _images: BehaviorSubject<StorageObject[] | null> = new BehaviorSubject(null);
    private _documents: BehaviorSubject<StorageObject[] | null> = new BehaviorSubject(null);

    constructor(
        private _authService: AuthService,
        private _httpClient: HttpClient)
    {
    }

    get images$(): BehaviorSubject<StorageObject[]>
    {
        return this._images;
    }

    get documents$(): BehaviorSubject<StorageObject[]>
    {
        return this._documents;
    }

    clearFilesCache() {
        this.images$.next([]);
        this.documents$.next([]);
    }

    getImages(): Observable<{ data: { list: StorageObject[] } }>
    {
        const orgIdOverride = this._authService.organisationIdOverride$.value;
        const orgOverrideQuery = orgIdOverride ? `organisation:"${orgIdOverride}" ` : '';

        return this._httpClient.post<{ data: { list: StorageObject[] } }>(environment.config.endpoints.files.url, {
            query: `query{list(path:"images" ${orgOverrideQuery}){fileName filePath metaData thumbnail }}`
        }).pipe(
            tap((response) => {
                for (let item of response.data.list) {
                    item.metaDataObject = JSON.parse(item.metaData);
                    item.thumbnailObject = JSON.parse(item.thumbnail);
                    item.fileType = item.metaDataObject?.contentType?.replace('image/', '').replace('svg+xml', 'svg')
                }

                this._images.next(response.data.list);
            })
        );
    }

    getDocuments(): Observable<{ data: { list: StorageObject[] } }>
    {
        const orgIdOverride = this._authService.organisationIdOverride$.value;
        const orgOverrideQuery = orgIdOverride ? `organisation:"${orgIdOverride}" ` : '';

        return this._httpClient.post<{ data: { list: StorageObject[] } }>(environment.config.endpoints.files.url, {
            query: `query{list(path:"pdf" ${orgOverrideQuery}){fileName filePath metaData thumbnail }}`
        }).pipe(
            tap((response) => {
                for (let item of response.data.list) {
                    item.metaDataObject = JSON.parse(item.metaData);
                    item.thumbnailObject = JSON.parse(item.thumbnail);
                    item.fileType = item.metaDataObject?.contentType?.replace('application/', '');
                }

                this._documents.next(response.data.list);
            })
        );
    }

    async getPublicUrl(file: StorageObject): Promise<string> {
        const fileUrl = file.metaDataObject.name;

        const asyncResult = await this._httpClient.post<{ data: { getanonymousurl: { url: string } } }>(environment.config.endpoints.files.url, {
            query: `query{getanonymousurl(file:"${fileUrl}"){ url }}`
        }).toPromise();

        return asyncResult.data.getanonymousurl.url;
    }

    async deleteFile(file: StorageObject): Promise<boolean> {
        const fileUrl = file.metaDataObject.name;

        const asyncResult = await this._httpClient.post<{ data: { delete: { url: string; action: string } } }>(environment.config.endpoints.files.url, {
            query: `query{delete(file:"${fileUrl}"){ url action }}`
        }).toPromise();

        if (asyncResult.data.delete.url) {
            const asyncDeleteResult = await this._httpClient.delete(asyncResult.data.delete.url, { params: {dni: true}, observe: 'response' }).toPromise();
            if (asyncDeleteResult.status === 204) {
                return true;
            }
        }

        return false;
    }

    async getFileUrl(file: StorageObject): Promise<string> {
        const fileUrl = file.metaDataObject.name;

        const asyncResult = await this._httpClient.post<{ data: { get: { url: string; action: string } } }>(environment.config.endpoints.files.url, {
            query: `query{get(file:"${fileUrl}"){ url action }}`
        }).toPromise();

        if (asyncResult.data.get.url) {
            return asyncResult.data.get.url;
        }

        return null;
    }

    async downloadFile(file: StorageObject): Promise<boolean> {
        // const fileUrl = file.metaDataObject.name;
        //
        // const asyncResult = await this._httpClient.post<{ data: { get: { url: string; action: string } } }>(environment.config.endpoints.files.url, {
        //     query: `query{get(file:"${fileUrl}"){ url action }}`
        // }).toPromise();

        const imageUrl = await this.getFileUrl(file);

        if (imageUrl) {
            const url = imageUrl + '&response-content-disposition=attachment';
            const a = document.createElement('a');
            a.href = url;
            a.download = file.fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
            return true;
        }

        return false;
    }

    async renameImage(file: StorageObject, newFileName: string): Promise<boolean> {
        const fileUrl = file.metaDataObject.name;
        const newFileUrl = fileUrl.replace(file.fileName, newFileName);

        const fileThumbnailUrl = file.metaDataObject.name.replace('/images', '/thumbs/images');
        const newFileThumbnailUrl = fileThumbnailUrl.replace(file.fileName, newFileName);

        const asyncResult = await this._httpClient.post<{ data: { rename: { url: string; action: string } } }>(environment.config.endpoints.files.url, {
            query: `query{rename(file:"${fileUrl}" newname:"${newFileUrl}"){ url action }}`
        }).toPromise();

        const asyncResultForThumbnail = await this._httpClient.post<{ data: { rename: { url: string; action: string } } }>(environment.config.endpoints.files.url, {
            query: `query{rename(file:"${fileThumbnailUrl}" newname:"${newFileThumbnailUrl}"){ url action }}`
        }).toPromise();

        return asyncResult?.data.rename.url === newFileUrl && asyncResultForThumbnail?.data.rename.url === newFileThumbnailUrl;
    }

    async renameDocument(file: StorageObject, newFileName: string): Promise<boolean> {
        const fileUrl = file.metaDataObject.name;
        const newFileUrl = fileUrl.replace(file.fileName, newFileName);

        const asyncResult = await this._httpClient.post<{ data: { rename: { url: string; action: string } } }>(environment.config.endpoints.files.url, {
            query: `query{rename(file:"${fileUrl}" newname:"${newFileUrl}"){ url action }}`
        }).toPromise();

        return asyncResult?.data.rename.url === newFileUrl;
    }

    uploadProgressChanged$: BehaviorSubject<number> = new BehaviorSubject(0);

    async uploadFile(queuedFile: QueuedFile, directory: 'images' | 'pdf'): Promise<boolean> {
        this.uploadProgressChanged$.next(0);

        const file = queuedFile.file;

        const fileUrl = `${directory}/${file.name}`;
        const fileType = file.type;
        const orgId = this._authService.getOrganisationId();

        const asyncResult = await this._httpClient.post<{ data: { insert: { url: string; action: string } } }>(environment.config.endpoints.files.url, {
            query: `query{insert(file:"${fileUrl}" type:"${fileType}" organisation:"${orgId}"){ url action }}`
        }).toPromise();

        if (asyncResult.data.insert.url) {
            const upload$ = this._httpClient.put(asyncResult.data.insert.url, file, { params: {dni: true}, reportProgress: true, observe: 'events' });
            upload$.subscribe((event) => {
                if (event.type == HttpEventType.UploadProgress) {
                    queuedFile.progress = Math.round(100 * (event.loaded / event.total));
                    this.uploadProgressChanged$.next(queuedFile.progress);
                }
            });
            const uploadResult = await upload$.toPromise();
            // if (uploadResult.status === 200) {
            if (uploadResult) {
                // If image, also need to send request to create a thumbnail for the new image
                if (directory === 'images') {
                    const thumbnailResult = await this._httpClient.post<{ data: { thumbnail: { url: string; action: string } } }>(environment.config.endpoints.files.url, {
                        query: `query{thumbnail(file:"${file.name}" type:"${fileType}" organisation:"${orgId}"){ url action }}`
                    }).toPromise();
                    if (thumbnailResult.data.thumbnail.url) {
                        return true;
                    }
                }
                else {
                    return true;
                }
            }
        }
        return false;
    }
}
