import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'app/core/user/user.types';
import { Scheme } from "../../../core/config/app.config";
import { FuseConfigService } from "../../../../@fuse/services/config";
import { MatIcon } from "@angular/material/icon";
import { AuthService } from "../../../core/auth/auth.service";

@Component({
    selector       : 'user',
    templateUrl    : './user.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'user'
})
export class UserComponent implements OnInit, OnDestroy
{
    user: User;
    avatar: string;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _fuseConfigService: FuseConfigService,
        private _authService: AuthService
    )
    {
    }

    ngOnInit(): void
    {
        // Subscribe to user changes
        this._authService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;
                this._changeDetectorRef.markForCheck();
            });

        // User first load
        this._authService.getRdUser(false);

        this._authService.userAvatar$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((avatar: string)  => {
                if (!this.avatar || !this.avatar.includes('data:image')) {
                    this.avatar = AuthService.resizeAvatar(avatar, 28);
                    this._changeDetectorRef.markForCheck();
                }
            });

        this._authService.userAvatarBlob$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((avatarBlob: string) => {
                if (avatarBlob) {
                    this.avatar = avatarBlob;
                    this._changeDetectorRef.markForCheck();
                }
            });

        this._authService.auth.user
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user) => {
                this._authService.setAvatar(user.providerData.find(provider => provider.providerId === 'google.com')?.photoURL ?? AuthService.getGravatarUrl(user.email));
                this._changeDetectorRef.markForCheck();
            });
    }

    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    signOut(): void
    {
        this._router.navigate(['/sign-out']);
    }
}
