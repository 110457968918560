import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from "./core/auth/auth.service";
import { OrganisationsService } from "./modules/organisations/organisations.service";
import { OrgUsersService } from "./modules/orgUsers/orgUsers.service";
import { ResourcesService } from "./modules/resources/resources.service";
import { FilesService } from "./modules/files/common/files.service";

@Injectable({
    providedIn: 'root'
})
export class OrgNavResolver implements Resolve<any>
{
    constructor(
        private _authService: AuthService,
        private _organisationsService: OrganisationsService,
        private _orgUsersService: OrgUsersService,
        private _resourcesService: ResourcesService,
        private _filesService: FilesService,
        private _router: Router)
    {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    {
        const organisationId = route.queryParamMap.get('organisation');
        if (organisationId && this._authService.getIsSuperAdmin()) {
            this.setOrganisationOverride(organisationId);
        }
    }

    private setOrganisationOverride(organisationId: string) {
        AuthService.localStorageSetItem('organisationIdOverride', organisationId);
        this._authService.organisationIdOverride$.next(organisationId);
        this._organisationsService.getOrganisation();

        this._orgUsersService.clearOrgUsersCache();
        this._resourcesService.clearResourcesCache();
        this._filesService.clearFilesCache();
    }
}
