import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { take } from 'rxjs/operators';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { AuthService } from "../../../core/auth/auth.service";

@Component({
    selector       : 'languages',
    templateUrl    : './languages.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'languages'
})
export class LanguagesComponent implements OnInit, OnDestroy
{
    availableLangs: AvailableLangs;
    activeLang: string;
    flagCodes: any;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _translocoService: TranslocoService
    )
    {
    }

    ngOnInit(): void
    {
        // Get the available languages from transloco
        this.availableLangs = this._translocoService.getAvailableLangs();

        // Subscribe to language changes
        this._translocoService.langChanges$.subscribe((activeLang) => {

            // Get the active lang
            this.activeLang = activeLang;

            // Update the navigation
            this._updateNavigation(activeLang);

            AuthService.localStorageSetItem('userLocale', activeLang);
        });

        // Set the country iso codes for languages for flags
        this.flagCodes = {
            'en': 'gb',
            'de': 'de'
        };
    }

    ngOnDestroy(): void
    {
    }

    setActiveLang(lang: string): void
    {
        // Set the active lang
        this._translocoService.setActiveLang(lang);
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

    /**
     * Update the navigation
     *
     * @param lang
     * @private
     */
    private _updateNavigation(lang: string): void
    {
        // Get the component -> navigation data -> item
        const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');

        // Return if the navigation component does not exist
        if ( !navComponent )
        {
            return null;
        }

        // Get the flat navigation data
        const navigation = navComponent.navigation;

        if (!navigation) return;

        // // Update the Forms Section titles
        // const formsSectionItem = this._fuseNavigationService.getItem('main', navigation);
        // if (formsSectionItem)
        // {
        //     this._translocoService.selectTranslate('Forms').pipe(take(1))
        //         .subscribe((translation) => {
        //             formsSectionItem.title = translation;
        //             navComponent.refresh();
        //         });
        // }

        // Get the Dashboard item and update its title
        const dashboardItem = this._fuseNavigationService.getItem('main.dashboard', navigation);
        if (dashboardItem)
        {
            this._translocoService.selectTranslate('Dashboard').pipe(take(1))
                .subscribe((translation) => {

                    // Set the title
                    dashboardItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        const submissionsItem = this._fuseNavigationService.getItem('main.submissions', navigation);
        if (submissionsItem)
        {
            this._translocoService.selectTranslate('Submissions').pipe(take(1))
                .subscribe((translation) => {
                    submissionsItem.title = translation;
                    navComponent.refresh();
                });
        }

        const usersItem = this._fuseNavigationService.getItem('main.users', navigation);
        if (usersItem)
        {
            this._translocoService.selectTranslate('Users').pipe(take(1))
                .subscribe((translation) => {
                    usersItem.title = translation;
                    navComponent.refresh();
                });
        }

        const formsItem = this._fuseNavigationService.getItem('main.forms', navigation);
        if (formsItem)
        {
            this._translocoService.selectTranslate('Forms').pipe(take(1))
                .subscribe((translation) => {
                    formsItem.title = translation;
                    navComponent.refresh();
                });
        }

        const filesItem = this._fuseNavigationService.getItem('main.files', navigation);
        if (filesItem)
        {
            this._translocoService.selectTranslate('Files').pipe(take(1))
                .subscribe((translation) => {
                    filesItem.title = translation;
                    navComponent.refresh();
                });

            const imagesItem = this._fuseNavigationService.getItem('main.files.images', navigation);
            if (imagesItem)
            {
                this._translocoService.selectTranslate('Images').pipe(take(1))
                    .subscribe((translation) => {
                        imagesItem.title = translation;
                        navComponent.refresh();
                    });
            }

            const documentsItem = this._fuseNavigationService.getItem('main.files.documents', navigation);
            if (documentsItem)
            {
                this._translocoService.selectTranslate('Documents').pipe(take(1))
                    .subscribe((translation) => {
                        documentsItem.title = translation;
                        navComponent.refresh();
                    });
            }
        }

        const resourcesItem = this._fuseNavigationService.getItem('main.resources', navigation);
        if (resourcesItem)
        {
            this._translocoService.selectTranslate('Resources').pipe(take(1))
                .subscribe((translation) => {
                    resourcesItem.title = translation;
                    navComponent.refresh();
                });

            const datasetsItem = this._fuseNavigationService.getItem('main.resources.datasets', navigation);
            if (datasetsItem)
            {
                this._translocoService.selectTranslate('Datasets').pipe(take(1))
                    .subscribe((translation) => {
                        datasetsItem.title = translation;
                        navComponent.refresh();
                    });
            }

            const headersItem = this._fuseNavigationService.getItem('main.resources.headers', navigation);
            if (headersItem)
            {
                this._translocoService.selectTranslate('Headers').pipe(take(1))
                    .subscribe((translation) => {
                        headersItem.title = translation;
                        navComponent.refresh();
                    });
            }

            const stylesItem = this._fuseNavigationService.getItem('main.resources.styles', navigation);
            if (stylesItem)
            {
                this._translocoService.selectTranslate('Styles').pipe(take(1))
                    .subscribe((translation) => {
                        stylesItem.title = translation;
                        navComponent.refresh();
                    });
            }

            const distlistsItem = this._fuseNavigationService.getItem('main.resources.distlists', navigation);
            if (distlistsItem)
            {
                this._translocoService.selectTranslate('Distribution').pipe(take(1))
                    .subscribe((translation) => {
                        distlistsItem.title = translation;
                        navComponent.refresh();
                    });
            }

            const processesItem = this._fuseNavigationService.getItem('main.resources.processes', navigation);
            if (processesItem)
            {
                this._translocoService.selectTranslate('Processes').pipe(take(1))
                    .subscribe((translation) => {
                        processesItem.title = translation;
                        navComponent.refresh();
                    });
            }
        }

        const organisationItem = this._fuseNavigationService.getItem('main.organisation', navigation);
        if (organisationItem)
        {
            this._translocoService.selectTranslate('Organisation').pipe(take(1))
                .subscribe((translation) => {
                    organisationItem.title = translation;
                    navComponent.refresh();
                });
        }

        const organisationsItem = this._fuseNavigationService.getItem('admin.organisations', navigation);
        if (organisationsItem)
        {
            this._translocoService.selectTranslate('Switch Organisation').pipe(take(1))
                .subscribe((translation) => {
                    organisationsItem.title = translation;
                    navComponent.refresh();
                });
        }
    }
}
