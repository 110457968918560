import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';

@Injectable()
export class AuthInterceptor implements HttpInterceptor
{
    /**
     * Constructor
     */
    constructor(private _authService: AuthService)
    {
    }

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const doNotIntercept = req.params.get('dni');
        if (doNotIntercept) {
            req = req.clone({
                url: req.url,
                params: req.params.delete('dni'),
            })
            return next.handle(req);
        }

        const doNotRedirect = req.params.get('dnr');
        if (doNotRedirect) {
            req = req.clone({
                url: req.url,
                params: req.params.delete('dnr'),
            })
        }

        return from(this.handle(req, next, !!doNotRedirect));
    }

    async handle(req: HttpRequest<any>, next: HttpHandler, doNotRedirect: boolean) : Promise<HttpEvent<any>> {
        if (this._authService.accessToken && AuthUtils.isTokenExpired(this._authService.accessToken)) {
            // If token is expired, try to refresh it with firebase then reauth with RD
            const checkAuthResult = await this._authService.checkAuth();
            if (!checkAuthResult) {
                // Sign out & reload the app
                this._authService.signOut();
                if (!doNotRedirect) {
                    location.reload();
                }
            }
        }

        // Request
        const newReq = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + this._authService.accessToken)
        });

        // Response
        return next.handle(newReq).pipe(
            catchError((error) => {
                // Catch "401 Unauthorized" responses
                if (error instanceof HttpErrorResponse && error.status === 401)
                {
                    // Sign out & reload the app
                    this._authService.signOut();
                    if (!doNotRedirect) {
                        location.reload();
                    }
                }

                return throwError(error);
            })
        ).toPromise();
    }
}
